<template>
  <section id="detail-verifikasi-kegiatan">
    <b-row>
      <b-col
        lg="3"
        cols="12"
      >
        <kegiatan-about :detail-program="dataProgram" />
      </b-col>

      <b-col
        lg="9"
        cols="12"
      >
        <detail-infrastruktur
          v-if="dataProgram.jenis_pengadaan_id == '2'"
          set-step="verifikasi"
        />
        <detail-aplikasi
          v-if="dataProgram.jenis_pengadaan_id == '3'"
          set-step="verifikasi"
        />
        <detail-data
          v-if="dataProgram.jenis_pengadaan_id == '6'"
          set-step="verifikasi"
        />
        <detail-non
          v-if="dataProgram.jenis_pengadaan_id == '7'"
          set-step="verifikasi"
        />
      </b-col>

    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'

import KegiatanAbout from '../kegiatan/detail-kegiatan/KegiatanAbout.vue'
import DetailInfrastruktur from '../kegiatan/detail/DetailKegiatanInfrastruktur.vue'
import DetailAplikasi from '../kegiatan/detail/DetailKegiatanAplikasi.vue'
import DetailData from '../kegiatan/detail/DetailKegiatanData.vue'
import DetailNon from '../kegiatan/detail/DetailKegiatanNon.vue'

export default {
  components: {
    BRow,
    BCol,

    KegiatanAbout,
    DetailInfrastruktur,
    DetailAplikasi,
    DetailData,
    DetailNon,
  },
  data() {
    return {
      userData: getUserData(),
      dataProgram: {
        utama: {},
        program: {},
        dokumen: {},
        data_info: {},
        layanan: {},
        aplikasi: {},
        infra: {},
        keamanan: {},
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role === 10 || (role > 69 && role < 80)) {
      next()
    } else {
      next('/')
    }
    if (!to.params.id) {
      next('/verifikasi-teknis')
    } else {
      next()
    }
  },
  created() {
    this.$http.get('/clearance/core-data', {
      params: {
        token: localStorage.getItem('userToken'),
        data_utama_id: this.$route.params.id,
      },
    })
      .then(res => {
        if (res.data.status === 'success') {
          this.dataProgram = res.data.data
        }
      })
  },
}
</script>
